import { generateRandomId } from "../../utils/helper";
import classNames from "classnames";

export default function Radio({
  active,
  onClick,
  label,
  error = false,
  ...rest
}) {
  const randomId = `radio_btn_${generateRandomId()}`;

  return (
    <div
      className={`custom-radio d-flex align-items-center gap-2 w-fit my-4 ${
        error && "error"
      }`}
      onClick={onClick}
      role="button"
    >
      <input
        type="radio"
        className={`form-check-input ${active ? "active" : ""}`}
        id={randomId}
        {...rest}
      />
      <label
        htmlFor={randomId}
        className={classNames({ "text-danger m-0 p-0": error })}
      >
        {label || ""}
      </label>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formModal: false,
};

export const uiSlice = createSlice({
  name: "UI",
  initialState,
  reducers: {
    setFormModal: (state, action) => ({
      ...state,
      formModal: action.payload,
    }),
    resetState: () => ({
      formModal: false,
    }),
  },
});

export const { setFormModal, resetState } = uiSlice.actions;

export const uiReducer = uiSlice.reducer;

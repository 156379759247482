import { useTranslation } from "react-i18next";
import hero_image from "../../assets/images/hero_image.png";
import { Fade } from "react-awesome-reveal";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setFormModal } from "../../redux/features/slices/ui";

export default function HeroSection() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleRedirect = () => {
    window.location.href = "#mobile_section";
    setTimeout(() => {
      dispatch(setFormModal(true));
    }, 1000);
  };

  return (
    <div className="container hero_section_wrapper">
      <div className="content_container col-xl-6">
        <Fade direction={"up"} cascade damping={0.8} triggerOnce>
          <div className="title">{t("hero_title")}</div>
          <div className="subtitle">{t("hero_subtitle")}</div>
          <Button
            className="btn btn-primary my-4 px-4 fs-18 fw-bold"
            style={{
              width: "fit-content",
            }}
            onClick={handleRedirect}
          >
            {t("hero_button")}
          </Button>
        </Fade>
      </div>
      <div className="hero_image">
        <img src={hero_image} alt="hero_image" />
      </div>
    </div>
  );
}

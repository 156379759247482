import React from "react";
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import PrimaryLogo from "../assets/images/logo_white.png";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="footer_section">
      <div className="container footer_container">
        <div className="row gap-4 w-100">
          {/* Logo and Description */}
          <div className="col">
            <a
              href="/"
              rel="noopener noreferrer"
              className="footer_logo_section"
            >
              <img src={PrimaryLogo} className="footer_logo" alt="Logo" />
            </a>
          </div>

          {/* Contact Section */}
          <div className="col-12 col-md-5">
            <div className="footer_contact">
              <h4>{t("contact_us")}</h4>
              <p>
                {t("customer_care_email")}{" "}
                <a href="mailto:care@dalfintech.com">care@dalfintech.com</a>
              </p>
              <p>
                {t("general_inqueries_email")}{" "}
                <a href="mailto:info@dalfintech.com">info@dalfintech.com</a>
              </p>
            </div>
          </div>

          {/* Social Icons */}
          <div className="col">
            <div className="footer_social">
              <h4>{t("follow_us")}</h4>
              <div className="social_icons">
                <a
                  href="https://x.com/dalfintech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitterX />
                </a>
                <a
                  href="https://www.linkedin.com/company/dal-finance"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer_bottom">
        <p>
          &copy; {t("footer_text").replace("year", new Date().getFullYear())} |{" "}
          <a
            href="/privacy_statement.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacy_statement")}
          </a>
        </p>
      </div>
    </footer>
  );
}

import parentReducer from "./features/parentReducer";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "dal",
  storage,
  blacklist: ["ui"],
};

export const removePersistedState = () => {
  persistor.purge();
};

const persistedReducer = persistReducer(persistConfig, parentReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

export default store;

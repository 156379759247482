import { combineReducers } from "redux";
import { langReducer } from "./slices/langSlice";
import { uiReducer } from "./slices/ui";

const ParentReducer = combineReducers({
  lang: langReducer,
  ui: uiReducer,
});

export default ParentReducer;

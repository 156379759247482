import React, { useEffect, useState } from "react";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import HeroSection from "./components/Home/hero_section";
import FinanceSection from "./components/Home/finance_section";
import StepsSection from "./components/Home/steps_section";
import MobileSection from "./components/Home/mobile_section";
import useLanguage from "./hooks/useLanguage";
import { useTranslation } from "react-i18next";
import { FullScreenLoader } from "./components/common/loader";
import { CookieManager } from "react-cookie-manager";
import "react-cookie-manager/style.css";

function App() {
  const { i18n, t } = useTranslation();
  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [showCookieModal, setShowCookieModal] = useState(false);

  // Check cookie consent on mount
  useEffect(() => {
    const cookieConsent = localStorage.getItem("cookie-consent");
    if (!cookieConsent) {
      setShowCookieModal(true);
    }
  }, []);

  useEffect(() => {
    const loadLanguageResources = async () => {
      switch (language) {
        case "ar":
          i18n.changeLanguage("ar");
          break;
        case "en":
          i18n.changeLanguage("en");
          break;
        default:
          break;
      }
    };

    if (language) {
      loadLanguageResources();
    }
  }, [language, i18n]);

  useEffect(() => {
    const loadStyle = async () => {
      if (language === "ar") {
        await import("./assets/scss/ar.scss");
      } else if (language === "en") {
        await import("./assets/scss/en.scss");
      }
      setTimeout(() => {
        setLoading(false);
      }, 300);
    };

    loadStyle();
  }, [language, i18n]);

  const handleAcceptAll = () => {
    setShowCookieModal(false);
  };

  const handleDeclineAll = () => {
    setShowCookieModal(false);
  };

  return (
    <>
      {showCookieModal && (
        <CookieManager
          translations={{
            title: t("cookie_title"),
            message: t("cookie_message"),
            buttonText: t("accept_all"),
            declineButtonText: t("decline_all"),
            manageButtonText: t("manage_cookies"),
            privacyPolicyText: t("privacy_policy"),
          }}
          showManageButton={true}
          privacyPolicyUrl="/privacy_statement.pdf"
          theme="light"
          displayType="popup"
          onAcceptAll={handleAcceptAll}
          onDeclineAll={handleDeclineAll}
        />
      )}
      {loading && <FullScreenLoader />}
      <Navbar />
      <div>
        <HeroSection />
        <FinanceSection />
        <StepsSection />
        <MobileSection />
      </div>
      <Footer />
    </>
  );
}

export default App;

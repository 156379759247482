import { CheckmarkIcon } from "react-hot-toast";

export default function Checkbox({
  active = false,
  setActive = () => {},
  label = "",
  error = false,
  required = false,
}) {
  return (
    <div
      className="d-flex gap-2 w-fit"
      onClick={() => setActive(!active)}
      role="button"
    >
      <div
        className={`custom-checkbox ${active && "active"} ${
          error && "border-danger"
        }`}
      >
        {active && <CheckmarkIcon />}
      </div>
      <p className={error && "text-danger"}>{label || ""}{required && <span className="text-danger">*</span>}</p>
    </div>
  );
}

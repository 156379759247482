import React from "react";

export default function Select({ error = "", label = "", children, ...rest }) {
  return (
    <div className={`w-full form-input custom-input mt-3`}>
      <select className="form-select mt-3" {...rest}>
        {children}
      </select>
      <label className="placeholder">{label}</label>
      {error !== "" && <p className="text-danger">{error}</p>}
    </div>
  );
}

import { Spinner } from "react-bootstrap";
import { Audio } from "react-loader-spinner";

export const FullScreenLoader = () => {
  return (
    <div className="pre-load">
      <Audio
        height="80"
        width="80"
        radius="9"
        color="white"
        ariaLabel="loading"
        wrapperStyle
        wrapperClass
      />
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <div className="mx-2">
      <Spinner size="sm" />
    </div>
  );
};
